
import Component from "vue-class-component";
import Vue from "vue";
import { loginModule } from "@/store/login/login";
import Button, { ButtonType } from "@/components/Ui/Button/Button.vue";
import { RouteLink } from "@/router";
import { User } from "@/types/user";
import { storage_url } from "@/api/apiCall";
import userPlaceholder from "@/assets/images/userPlaceholder.png";
import { goBackOrPush } from "@/utils/utils";
import NotificationsDropdown from "@/components/Header/components/NotificationsDropdown/NotificationsDropdown.vue";
import LanguagePicker from "@/components/Header/components/LanguagePicker.vue";

@Component({
    components: { NotificationsDropdown, Button, LanguagePicker },
})
export default class HeaderRightPartAdmin extends Vue {
    ButtonType = ButtonType;

    userPhoto = this.profilePicture;

    get isAddNew(): boolean {
        return this.$route.path.includes(RouteLink.Add);
    }

    onAddNewGoBack(): void {
        this.$router.replace(RouteLink.Dashboard);
    }

    onAddNewClicked(): void {
        this.$router.push(RouteLink.Add);
    }

    onLogoutClicked(): void {
        loginModule.performLogoutAction();
    }

    goToSettings(): void {
        goBackOrPush(RouteLink.UserSettings);
    }

    replaceByUserPlaceholder(): void {
        this.userPhoto = userPlaceholder;
    }

    get user(): User | null {
        return loginModule.user;
    }

    get profilePicture(): string {
        return this.user?.profilePicture?.thumbnail ? (storage_url + this.user?.profilePicture.thumbnail.thumbnailUrl) : userPlaceholder;
    }
}
