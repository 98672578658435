<template>
  <div class="language-picker">
    <select v-model="selectedLanguage" @change="changeLanguage" >
      <option v-for="lang in languages" :key="lang.code" :value="lang.code">
        {{ lang.name }}
      </option>
    </select>
  </div>
</template>


<script>
import {getCurrentLang} from "@/utils/utils";
import {StorageKeys} from "@/utils/consts";

export default {
  data() {
    return {
      selectedLanguage: getCurrentLang(),
      languages: [
        {
          code: 'en',
          name: 'EN',
        },
        {
          code: 'bs',
          name: 'BS',
        },
      ],
    };
  },
  methods: {
    changeLanguage() {
      localStorage.setItem(StorageKeys.LANGUAGE, this.selectedLanguage);
      this.$i18n.locale = this.selectedLanguage;
    },
  },
};
</script>

<style scoped>
.language-picker {
  display: flex;
  align-items: center;
}

select {
  font-size: .8rem;
  padding: .9rem 0.4rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: linear-gradient(0deg, rgba(24, 210, 143, 0.06), rgba(24, 210, 143, 0.06)), #FFFFFF;
  color: $lightGrey;
  font-weight: bold;
  box-shadow: 0 8px 12px rgba(153, 155, 168, 0.15);
  transition: all 150ms ease-in-out;
  margin-left: 15px;

  &:focus,
  &:active,
  &:hover {
    outline: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}
</style>